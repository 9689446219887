@tailwind components;
@layer components {
  .option {
    @apply cursor-pointer hover:bg-gray-100 transition duration-200 ease-out p-2 rounded-lg;
  }
}
button {
  text-decoration: none !important;
  border: none !important;
  outline: none !important;
}
:root {
  --primary-color: #00996b;
  --dark-color: #00685c;
}

* {
  padding: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.green {
  background: #00996b !important;
  color: #fff !important;
  font-weight: 500 !important;
}
.green :hover {
  background: #00685c !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #04796a !important;
}
.MuiLinearProgress-colorPrimary {
  background-color: #04796a29 !important;
}
.MuiSnackbarContent-root {
  background-color: white !important;
  color: black !important;
}
.centered-div {
  position: absolute;
  width: 300px;
  height: 200px;
  z-index: 15;
  top: 50%;
  background: white;
  left: 50%;
  margin: -100px 0 0 -150px;
  text-align: center;
  box-shadow: 10px 10px 10px #d1d9e6, -10px -10px 10px #f9f9f9;
}

.page-height {
  height: 1066px !important;
  width: 826px !important;
}
.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  height: 0 !important;
}
.editor-toolbar-top {
  top: 56.5px !important;
}

.tab-icon {
  margin-top: 10px;
}

.x-spreadsheet-sheet {
  height: 83vh !important;
}

.equal-spaced-view {
  display: grid;
  grid-template-columns: repeat(2, calc(100% / 2));
}

button:focus {
  outline: none;
}
select:focus {
  outline: none;
}
.side-bar {
  z-index: 3;
  height: 84vh;
  position: fixed;
  right: 0;
  top: 0;
  margin-top: 113px;
  background: white;
  width: 400px;
  text-align: left;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
  background-color: #fff;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 15px;
}
.side-bar-full {
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  text-align: left;
  z-index: 1;
  background: white;
}

.MuiTab-wrapper {
  width: 100%;
  display: table !important;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.headerImage {
  max-height: 22.5vw;
  /* max-width: 90vw; */
  height: 160px;
  /* width: 640px; */
  background-size: cover;
  background-position: center;
}
.unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: default;
}
.rounded-paper {
  margin-top: 1em;
}

.question-text {
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  color: #202124;
  min-width: 0%;
}
.capitalize {
  text-transform: none;
}
.MuiInputBase-input.Mui-disabled {
  opacity: 1;
  color: black !important;
}
.MuiFormControlLabel-label.Mui-disabled {
  color: black !important;
}

.table-style {
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: #202124;
  background-color: #f8f9fa;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  padding: 10px;
  white-space: pre-line;
  margin-bottom: 10px;
  cursor: pointer;
}
.MuiTableCell-root {
  border-bottom: 5px solid white !important;
}

.section-title {
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  -webkit-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  align-items: center;
  color: #202124;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.add-hover:hover {
  --tw-border-opacity: 1;
  border-color: rgb(29 78 216 / var(--tw-border-opacity));
}

.custom-disabled .Mui-disabled {
  color: rgb(0, 82, 204) !important;
  border-color: rgb(0, 82, 204) !important;
}
[class*="PrivateTabIndicator-colorPrimary"] {
  height: 3.3px !important;
  border-radius: 20px;
}
@media only screen and (max-width: 480px) {
  .type-selector {
    height: 4em;
    margin-bottom: 40px;
  }
}
.menu-icon {
  color: gray;
  margin: 7px;
}
.image-uploader-dialog [class^="MuiPaper-root-"] {
  width: 90%;
  height: 90%;
  max-width: 100%;
}
.header-image-section {
  /* max-height: 25vw; */
  /* max-width: 100vw; */
  height: 154px;
  /* width: 770px; */
}

.selected-image {
  border: 7px solid #2196f3;
  box-shadow: 4px 12px 7px 14px #0a0a0a6b;
}

.answer-item {
  padding: 10px;
  margin: 4px 2px 2px -2px;
  width: 97%;
}
.answer-correct-item {
  background-color: #e6f4ea;
  border-radius: 6px;
}
.answer-wrong-item {
  background-color: #f4e6e6;
  border-radius: 6px;
}
.insights-item {
  background-color: rgb(248, 249, 250);
  margin: 10px;
  flex: 1 1 auto;
  padding: 8px;
  border-radius: 4px;
}
.insights-item > p {
  font-family: Roboto, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 16px;

  color: #202124;
}
.insights-item > p:first-child {
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: #202124;
  font-weight: 500;
}

.information-container {
  overflow-y: auto;
  max-height: 500px;
  overflow-x: hidden;
}

.response-number-item {
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 50%;
  margin: 5px !important;
  text-align: center;
  padding-top: 7px;
}
.response-number-current {
  color: white !important;
  background-color: gray !important;
}
.response-number-error {
  color: white !important;
  background-color: red !important;
}
.response-number-visited {
  color: white !important;
  background-color: #4caf50 !important;
}
.response-number-not-visited {
  color: black !important;
  background-color: whitesmoke !important;
}
.fileIcon {
  color: #fff;
  font-size: 10px;
  margin-left: 8px;
  margin-top: -23px;
  position: absolute;
  text-transform: uppercase;
}
